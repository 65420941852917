<template>
  <div class="clickNavigator" v-resize="onResize">
    <div
      ref="prevPage"
      class="prevPage"
      @click="$emit('go-to-prev-page')"
    ></div>
    <div
      ref="nextPage"
      class="nextPage"
      @click="$emit('go-to-next-page')"
    ></div>
  </div>
</template>

<script>
import { debounce } from "lodash";
const debug = require("debug")("eblish.components.click_navigator");

const PREV = "PREV";
const NEXT = "NEXT";

export default {
  name: "ClickNavigator",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    flipbook: {
      type: Object,
    },
  },
  data() {
    return {
      isZoomedOut: true,
    };
  },
  watch: {
    page() {
      this.setDisplayClasses();
    },
    "flipbook.animatingCenter": debounce(
      function () {
        this.setDisplayClasses();
      },
      1000,
      {
        leading: true,
        trailing: true,
      }
    ),
    // Hide when zoomed in
    "flipbook.zoom": debounce(
      function () {
        this.isZoomedOut = this.flipbook.zoom == 1;
        this.setDisplayClasses();
      },
      1000,
      {
        leading: false,
        trailing: true,
      }
    ),
  },
  mounted: function () {
    this.setDisplayClasses();
  },
  methods: {
    setPageClass(sourcePage, targetPage, mode) {
      debug("in setPageClass of ClickNavigator");
      const pageDimensions = sourcePage.getBoundingClientRect();
      const widthOfImage = pageDimensions.width;
      if (!widthOfImage || !targetPage.style) {
        setTimeout(() => {
          this.setDisplayClasses();
        }, 1000);
        return;
      }
      const bookPages = document.getElementsByClassName("page fixed");
      const singlePageDisplayed = bookPages.length == 1;
      const widthOfControl =
        pageDimensions.width * (singlePageDisplayed ? 0.5 : 0.85);
      if (mode == PREV) {
        targetPage.style.top = `${pageDimensions.top}px`;
        targetPage.style.left = `${
          pageDimensions.left +
          (singlePageDisplayed ? 0 : pageDimensions.width - widthOfControl)
        }px`;
        targetPage.style.width = `${widthOfControl}px`;
        targetPage.style.height = `${pageDimensions.height}px`;
      } else if (mode == NEXT) {
        targetPage.style.top = `${pageDimensions.top}px`;
        targetPage.style.width = `${widthOfControl}px`;
        targetPage.style.left = `${
          pageDimensions.left +
          (singlePageDisplayed ? pageDimensions.width - widthOfControl : 0)
        }px`;
        targetPage.style.height = `${pageDimensions.height}px`;
      }
    },
    onResize: function () {
      this.setDisplayClasses();
    },
    setDisplayClasses: function () {
      const bookPages = document.getElementsByClassName("page fixed");
      if (bookPages.length == 0 || !this?.$refs?.prevPage?.style) {
        setTimeout(() => {
          this.setDisplayClasses();
        }, 1000);
        return;
      }
      if (this.flipbook?.animatingCenter || !this.isZoomedOut) {
        this.$refs.prevPage.style.display = "none";
        this.$refs.nextPage.style.display = "none";
        return;
      }
      debug("in setDisplayClasses of ClickNavigator");
      this.$refs.prevPage.style.display = "";
      this.$refs.nextPage.style.display = "";

      this.setPageClass(bookPages[0], this.$refs.prevPage, PREV);
      this.setPageClass(
        bookPages[bookPages.length - 1],
        this.$refs.nextPage,
        NEXT
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.clickNavigator {
  .prevPage,
  .nextPage {
    position: fixed;
    cursor: pointer;
    background: #000a22;
    opacity: 0;
  }
}
</style>
